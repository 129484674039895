import { render, staticRenderFns } from "./PricingOverrideTable.vue?vue&type=template&id=4f381ca8&scoped=true&"
import script from "./PricingOverrideTable.vue?vue&type=script&lang=ts&"
export * from "./PricingOverrideTable.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4f381ca8",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/html/verification-system-staging/Web/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('4f381ca8')) {
      api.createRecord('4f381ca8', component.options)
    } else {
      api.reload('4f381ca8', component.options)
    }
    module.hot.accept("./PricingOverrideTable.vue?vue&type=template&id=4f381ca8&scoped=true&", function () {
      api.rerender('4f381ca8', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/class-components/fee-schedules/fee-schedules-more/components/PricingOverrideTable.vue"
export default component.exports